import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import truck1 from "../../../assets/img/esg/esgFour/truck1.webp";
import truck2 from "../../../assets/img/esg/esgFour/truck2.webp";
import truck3 from "../../../assets/img/esg/esgFour/truck3.webp";
import truck4 from "../../../assets/img/esg/esgFour/truck4.webp";
import plvb from "../../../assets/img/esg/esgFour/DOW_logo_Membro_Ouro 1.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";
import arrowRight from "../../../assets/img/components/arrow-right.webp";

import 'swiper/css';
import 'swiper/css/navigation';

const data = [
    {
      id: 1,
      title: "Programa de Logística Verde Brasil (PLVB)",
      class: "card1",
      content: [
        'A GH participa do Programa de Logística Verde Brasil (PLVB), onde é reconhecida como operador Selo Ouro, que reúne empresas comprometidas com a responsabilidade socioambiental. No PLVB, a GH trabalha para reduzir o impacto ambiental de suas operações logísticas.',
        'O PLVB visa reduzir as emissões de CO2 e melhorar a eficiência logística no Brasil, aplicando conhecimentos em sustentabilidade. O programa capacita embarcadores, operadores de transporte e prestadores de serviços logísticos para adotar práticas mais sustentáveis em toda a cadeia de suprimentos.'
      ],
      imageUrl: truck1,
    },
    {
        id: 2,
        title: "Observatório Nacional de Segurança Viária (Maio Amarelo)",
        class: "card2",
        content: [
          'O Maio Amarelo é um movimento internacional de conscientização para a redução de acidentes de trânsito. O objetivo é garantir que o trânsito seja seguro para todos. Em 11 de maio de 2011, a ONU declarou a Década de Ação para Segurança no Trânsito, fazendo de maio um mês de referência mundial para avaliar as ações realizadas globalmente.',
          ' O movimento busca colocar o tema do trânsito em pauta, estimulando a participação da população, empresas, governos e entidades. O amarelo simboliza atenção, sinalização e advertência no trânsito.'
        ],
        imageUrl: truck2,
    },
    {
        id: 3,
        title: "Conscientização sobre o Câncer de Mama (Outubro Rosa)",
        class: "card3",
        content: [
          'O Outubro Rosa é uma campanha global de conscientização sobre o câncer de mama. Durante todo o mês de outubro, ela promove a importância da prevenção e do diagnóstico precoce. Incentiva exames regulares e o autoexame das mamas, além de oferecer apoio às pacientes e suas famílias. O símbolo do laço rosa representa solidariedade e luta pela saúde das mulheres, mobilizando a sociedade para a causa.'
        ],
        imageUrl: truck3,
    }, 
    {
      id: 4,
      title: "Conscientização sobre o Câncer de Próstata (Novembro Azul)",
      class: "card4",
      content: [
        'O Novembro Azul é uma campanha dedicada à conscientização sobre o câncer de próstata. Durante o mês, ela incentiva homens a realizarem exames regulares e a consultarem seus médicos. O movimento busca desmistificar tabus, promover o autocuidado e oferecer apoio aos pacientes e suas famílias. O símbolo do laço azul representa solidariedade e a importância da saúde masculina, mobilizando a sociedade para a prevenção e o diagnóstico precoce.'
      ],
      imageUrl: truck4,
    }
  ];

function EsgFour(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
        <div id="esgFour" className={`esgFour ${isVisible ? 'visible' : ''}`}>
          <div className="esgFourTitle">
            <h1 className={`${isVisible ? 'visible' : ''}`}><span>Nossas campanhas</span> anuais</h1>
            <p className={`${isVisible ? 'visible' : ''}`}>
              Conscientes do impacto social que podemos causar, promovemos várias campanhas anuais que consideramos 
              essenciais para a comunidade. Para maximizar esse impacto, utilizamos nossas carretas como uma ferramenta 
              de promoção e conscientização, ampliando o alcance e a eficácia dessas iniciativas entre a população.
            </p>
          </div>
          <Swiper
            className={`${isVisible ? 'visible' : ''}`} 
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
            modules={[Navigation]} 
            /* breakpoints={breakpoints} */
            slidesPerView={'1'}
            spaceBetween={200}
            speed={800}
            >
            <div className={`swiper-button-prev ${isVisible ? 'visible' : ''}`}><img src={arrowLeft} width={'40px'} alt="" /></div>
              {
                data.map( (item) => (
                  <SwiperSlide key={item.id}>
                    <div className={`card ${item.class}`}>
                      <div className="cardText">
                        <h3 className={`${isVisible ? 'visible' : ''}`}>{item.title}</h3>
                        <h4 className={`${isVisible ? 'visible' : ''}`}>{item.subtitle}</h4>
                        <p className={`${isVisible ? 'visible' : ''}`}>{item.content}</p>
                      </div>
                      <div className="cardImg">
                        <img src={plvb} className={`plvb ${isVisible ? 'visible' : ''}`} alt="" width={'150px'}/>
                        <img className={`${isVisible ? 'visible' : ''}`} src={item.imageUrl} alt="" width={'700px'}/>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            <div className={`swiper-button-next ${isVisible ? 'visible' : ''}`}><img src={arrowRight} width={'40px'} alt="" /></div>
          </Swiper>
        </div>
        </VisibilitySensor>
    )
}

export default EsgFour;