import React, { useState } from "react";
import { Link } from "react-router-dom";

import VisibilitySensor from "react-visibility-sensor";

import logo from "../assets/icon/logo.png";

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`footer-bg ${isVisible ? "visible" : ""}`}>
        <div className="footer">
          <div className="logo">
            <img
              className={`${isVisible ? "visible" : ""}`}
              src={logo}
              alt="Logotipo da empresa"
              width={"250px"}
            />
            <div className={`line ${isVisible ? "visible" : ""}`}></div>
          </div>
          <div className={`address ${isVisible ? "visible" : ""}`}>
            <p>R. Francisco Reis, 1402 - Cordeiros, Itajaí - SC, 88311-750</p>
          </div>
          <div className={`rights ${isVisible ? "visible" : ""}`}>
            <p>
              © 2024 - GH Solucionador Logístico | Todos os Direitos Reservados
            </p>
          </div>
          <div className={`singlec ${isVisible ? "visible" : ""}`}>
            <p>
              A product developed by{" "}
              <a
                href="https://singlec.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Singlec
              </a>
            </p>
          </div>
        </div>
        <div className="footer2">
          <ul>
            <h2>Empresa</h2>
            <div className="underline"></div>
            <li>
              <a href={"/about"}>Sobre</a>
            </li>
            <li>
              <a href={"/services"}>Soluções</a>
            </li>
            <li>
              <a href={"/warehouseStructure"}>Armazém</a>
            </li>
            <li>
              <a href={"/fleetStructure"}>Frota</a>
            </li>
          </ul>
          <ul>
            <h2>Conteúdo</h2>
            <div className="underline"></div>
            <li>
              <a href={"/blog"}>Blog</a>
            </li>
            <li>
              <a href={"/esg"}>ESG</a>
            </li>
          </ul>
          <ul>
            <h2>Contato</h2>
            <div className="underline"></div>
            <li>
              <a href={"/contact"}>Orçamento</a>
            </li>
            <li>
              <a href={"/anonymousReports"}>Canal de Denúncias</a>
            </li>
            <li>
              <a href={"/career"}>#CarreirasGH</a>
            </li>
          </ul>
          <ul className="listThree">
            <h2>Filiais</h2>
            <div className="underline"></div>
            <li>Itajaí - SC</li>
            <li>Garuva - SC</li>
            <li>Juiz de Fora - MG</li>
            <li>Nova Santa Rita - RS</li>
            <li>Campina Grande - PR</li>
            <li>Recife - PE</li>
            <li>Paranaíba - MS</li>
            <li>Taboão da Serra - SP</li>
            <li>Jacareí - SP</li>
            <li>Resende - RJ</li>
          </ul>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default Footer;
