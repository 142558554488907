import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import map from "../../../assets/img/home/map/map.webp";
import mapButton from "../../../assets/img/components/button.webp";
import mapPin from "../../../assets/img/home/map/map-pin.webp";

function HomeMap() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeMap">
          <div className="homeMap1">
            <img
              className={`${isVisible ? "visible" : ""}`}
              src={map}
              alt=""
              width={"700px"}
              height={"700px"}
            />
            <div className={`pin1 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card1 card">
                <h1>Filial de Recife - PE</h1>
                <p>
                  Endereço: Av. Fernando Simoes Barbosa, 266, Sala 203, Boa
                  Viagem - CEP 51.020-390
                </p>
              </div>
            </div>
            <div className={`pin4 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card4 card">
                <h1>Filial de Paranaíba - MS</h1>
                <p>
                  Endereço: Rod. BR 158 KM 89, S/N, Km 90 Anexo Auto Posto
                  Daniel, Santo Antonio - CEP 79.500-000
                </p>
              </div>
            </div>
            <div className={`pin3 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card3 card">
                <h1>Filial de Taboão da Serra - SP</h1>
                <p>
                  Endereço: Av. Vida Nova, 28, Bloco A sala 609 Cond Centro
                  Empvida Nova, Jardim Maria Rosa - CEP 06.764-045
                </p>
              </div>
            </div>
            <div className={`pin10 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card10 card">
                <h1>Filial de Jacareí - SP</h1>
                <p>
                  Endereço: Av. Industrial, 241 - Jardim Emilia - CEP 12321-500
                </p>
              </div>
            </div>
            <div className={`pin6 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card6 card">
                <h1>Filial de Juiz de Fora - MG</h1>
                <p>
                  Endereço: Av. Presidente Juscelino Kubitscheck, 6911, Sala
                  208, Benfica - CEP 36.092-060
                </p>
                <a
                  href="https://www.google.com/maps/place/Av.+Presidente+Juscelino+Kubitschek,+6911+-+208+-+Benfica,+Juiz+de+Fora+-+MG,+36091-000/@-21.6867722,-43.4350833,19z/data=!3m1!4b1!4m8!1m2!10m1!1e1!3m4!1s0x989e45476d852f:0xb154517d390e9e59!8m2!3d-21.6867734!4d-43.4344396?entry=ttu"
                  target="_blank"
                  rel="noreferrer"
                >
                  Vizualizar no mapa
                </a>
              </div>
            </div>
            <div className={`pin7 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card7 card">
                <h1>Filial de Resende - RJ</h1>
                <p>
                  Endereço: Av. Tenente Coronel Adalberto Mendes, 682, Loja QA2
                  LB, Liberdade, Resende - CEP 27.521-131
                </p>
              </div>
            </div>
            <div className={`pin5 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card5 card">
                <h1>Filial de Campinha Grande - PR</h1>
                <p>
                  Endereço: Rod. Régis Bittencourt, S/N, KM 31, Sala 06, Anexo
                  ao posto Pelanda, Ribeirão Grande - CEP 83.430-000
                </p>
              </div>
            </div>
            <div className={`pin8 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card8 card">
                <h1>Filial de Garuva - SC</h1>
                <p>
                  Endereço: Rod. SC 417 Maximo Jamur, 11805, Cond Braspark
                  Galpão C Modulo 1, Mina Velha - CEP 89.248-000
                </p>
                <a
                  href="https://www.google.com/maps/search/Rod.+SC+417+Maximo+Jamur,+11805,+Cond+Braspark+Galp%C3%A3o+C+Modulo+1,+Mina+Velha+-+CEP+89.248-000./@-26.0159145,-48.7479097,19z?entry=ttu"
                  target="_blank"
                  rel="noreferrer"
                >
                  Vizualizar no mapa
                </a>
              </div>
            </div>
            <div className={`pin2 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card2 card">
                <h1>Matriz em Itajaí - SC</h1>
                <p>
                  Endereço: Rua Francisco Reis, 1402, Cordeiros - CEP 88.311-750
                </p>
                <a
                  href="https://www.google.com/maps/place/GH+Operador+Log%C3%ADstico/@-26.9067991,-48.7188215,15.25z/data=!4m6!3m5!1s0x94d8cc7fb0048143:0xb070be843b40155a!8m2!3d-26.903912!4d-48.7120914!16s%2Fg%2F11gzshmr0?entry=ttu"
                  target="_blank"
                  rel="noreferrer"
                >
                  Vizualizar no mapa
                </a>
              </div>
            </div>
            <div className={`pin9 pin ${isVisible ? "visible" : ""}`}>
              <img src={mapPin} alt="" width={"40px"} height={"40px"} />
              <div className="card9 card">
                <h1>Filial de Nova Santa Rita - RS</h1>
                <p>
                  Endereço: Av. Santa Rita, 826, Sala 03, Centro - CEP
                  92.480-000
                </p>
              </div>
            </div>
          </div>
          <div className="homeMap2">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              Perto de você para ir mais longe
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              Descomplicamos o transporte da sua carga em todo o país, graças à
              nossa rede de pontos estratégicos.
            </p>
            <span className={`${isVisible ? "visible" : ""}`}>
              Sua escolha inteligente está mais próxima do que você imagina,
              pronta para impulsionar seus negócios.
            </span>
            <a href="/contact" reloadDocument>
              <img
                className={`${isVisible ? "visible" : ""}`}
                src={mapButton}
                alt=""
                width={"288px"}
                height={"106px"}
              />
              <p>Entre em contato</p>
            </a>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeMap;
