import React, { useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import phone from '../../../assets/img/home/feed/phone.webp';

import VisibilitySensor from 'react-visibility-sensor';

function HomeFeed(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    const clip = document.querySelectorAll(".clip");
    for (let i = 0; i < clip.length; i++) {
      clip[i].addEventListener("mouseenter", function (e) {
        clip[i].play();
      });
      clip[i].addEventListener("mouseout", function (e) {
        clip[i].pause();
      });
    }
  
    const [feedList, setFeedList] = React.useState([]);
    const [itemId0, setItemId0] = useState(null);
    const [itemId1, setItemId1] = useState(null);
    const [itemId2, setItemId2] = useState(null);
    const [itemId3, setItemId3] = useState(null);
    const [itemId4, setItemId4] = useState(null);
  
    async function getInstaFeed() {
      const token = process.env.REACT_APP_INSTA_TOKEN;
      const fields = "media_url,media_type,permalink,caption, timestamp";
      const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=${fields}`;
  
      const { data } = await axios.get(url);
      const images = data.data.filter(
        (item) =>
          item.media_type === "IMAGE" ||
          item.media_type === "CAROUSEL_ALBUM"
          /* item.media_type === "VIDEO" */
      );
      const limitedImages = images.slice(0, 5); // Define o número máximo de imagens a serem exibidas (neste exemplo, 8)
      setFeedList(limitedImages);
      setItemId0(limitedImages[0].id);
      setItemId1(limitedImages[1].id);
      setItemId2(limitedImages[2].id);
      setItemId3(limitedImages[3].id);
      setItemId4(limitedImages[4].id);
    }
  
    React.useEffect(() => {
      getInstaFeed();
    }, []);
  
    /* const [isVisible, setIsVisible] = useState(false);
  
    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    }; */

    const breakpoints = {
        1000: {
          slidesPerView: 3,
          spaceBetween:50,
        },
        769: {
          slidesPerView: 3,
            spaceBetween:50,
        },
        0: {
          slidesPerView: 1,
        },
      };

    return(
        <section>
            <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
            <div className="homeFeed">
                <div className="homeFeedTitle">
                    <h1 className={`${isVisible ? 'visible' : ''}`}>Explore nossas redes sociais e mantenha-se atualizado com nossas postagens.</h1>
                </div>
                <div className="homeFeed2">
                    <div className={`phone ${isVisible ? 'visible' : ''}`}>
                        <img src={phone} alt="" />
                    </div>
                    <Swiper
                        slidesPerView={3}
                        breakpoints={breakpoints}
                        className={`${isVisible ? 'visible' : ''}`}
                        loop={true}
                    >
                    <SwiperSlide>
                    <div className="card1">
                        {feedList.map((item) => {
                            if (item.id === itemId0) {
                            return (
                                <>
                                {item.media_type === "IMAGE" ||
                                item.media_type === "CAROUSEL_ALBUM" ? (
                                    <div className="card-media">
                                        <img src={item.media_url} rel="preload" alt="" />
                                        <div className="media-text">
                                            <p>{item.caption}</p>
                                            <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-media">
                                        <video
                                            class="clip"
                                            id="media-video"
                                            type="video/mp4"r
                                            rel="preload"
                                            loop
                                            Muted
                                        >
                                            <source src={item.media_url} />
                                        </video>
                                        <div className="media-text text-video">
                                            <p>{item.caption}</p>
                                            <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                        </div>
                                    </div>
                                )}
                                </>
                            );
                            }
                            return null;
                        })}
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="card2">
                        {feedList.map((item) => {
                            if (item.id === itemId1) {
                            return (
                                <>
                                {item.media_type === "IMAGE" ||
                                item.media_type === "CAROUSEL_ALBUM" ? (
                                    <div className="card-media">
                                    <img src={item.media_url} rel="preload" alt="" />
                                    <div className="media-text">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                ) : (
                                    <div className="card-media">
                                    <video
                                        class="clip"
                                        id="media-video"
                                        type="video/mp4"r
                                        rel="preload"
                                        loop
                                        Muted
                                    >
                                        <source src={item.media_url} />
                                    </video>
                                    <div className="media-text text-video">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                )}
                                </>
                            );
                            }
                            return null;
                        })}
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="card3">
                        {feedList.map((item) => {
                            if (item.id === itemId2) {
                            return (
                                <>
                                {item.media_type === "IMAGE" ||
                                item.media_type === "CAROUSEL_ALBUM" ? (
                                    <div className="card-media">
                                    <img src={item.media_url} rel="preload" alt="" />
                                    <div className="media-text">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                ) : (
                                    <div className="card-media">
                                    <video
                                        class="clip"
                                        id="media-video"
                                        type="video/mp4"r
                                        rel="preload"
                                        loop
                                        Muted
                                    >
                                        <source src={item.media_url} />
                                    </video>
                                    <div className="media-text text-video">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                )}
                                </>
                            );
                            }
                            return null;
                        })}
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="card4">
                        {feedList.map((item) => {
                            if (item.id === itemId3) {
                            return (
                                <>
                                {item.media_type === "IMAGE" ||
                                item.media_type === "CAROUSEL_ALBUM" ? (
                                    <div className="card-media">
                                    <img src={item.media_url} rel="preload" alt="" />
                                    <div className="media-text">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                ) : (
                                    <div className="card-media">
                                    <video
                                        class="clip"
                                        id="media-video"
                                        type="video/mp4"r
                                        rel="preload"
                                        loop
                                        Muted
                                    >
                                        <source src={item.media_url} />
                                    </video>
                                    <div className="media-text text-video">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                )}
                                </>
                            );
                            }
                            return null;
                        })}
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="card5">
                        {feedList.map((item) => {
                            if (item.id === itemId4) {
                            return (
                                <>
                                {item.media_type === "IMAGE" ||
                                item.media_type === "CAROUSEL_ALBUM" ? (
                                    <div className="card-media">
                                    <img src={item.media_url} rel="preload" alt="" />
                                    <div className="media-text">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                ) : (
                                    <div className="card-media">
                                    <video
                                        class="clip"
                                        id="media-video"
                                        type="video/mp4"r
                                        rel="preload"
                                        loop
                                        Muted
                                    >
                                        <source src={item.media_url} />
                                    </video>
                                    <div className="media-text text-video">
                                        <p>{item.caption}</p>
                                        <a href={item.permalink} target="_blank" rel="noreferrer">ver mais...</a>
                                    </div>
                                    </div>
                                )}
                                </>
                            );
                            }
                            return null;
                        })}
                    </div>
                    </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            </VisibilitySensor>
        </section>
    )
}

export default HomeFeed;